<template>
<a-layout-content>
    <div class="header_div">
        <a-input-search placeholder="Please enter key words" style="width: 200px" @search="onSearch" class="align" />
        <a-button type="primary" :loading="startPaueLoading" icon="play-circle" class="align btn_add" style="background-color: #469824;border-color: #469824;margin-left:10px;" @click="onStartPause('all','start')">
            Start all
        </a-button>
        <a-button type="primary" :loading="startPaueLoading" icon="pause-circle" class="align btn_add" @click="onStartPause('all','pause')">
            Stop all
        </a-button>
    </div>
    <a-table rowKey="id" :columns="columns" :data-source="data" :loading="loading" :pagination="pagination">
        <span slot="name" slot-scope="text,record">
            <a-tooltip placement="top">
                <template slot="title">
                    <span>Open the script</span>
                </template>
                <a-icon class="pointer" type="link" @click="onTz(record.uuid)" /> <b style="margin-left:5px;">{{ text }}</b>
            </a-tooltip>
        </span>

        <a-tag slot="type" slot-scope="text" color="#04b2ef" v-if="text=='cron'">
            Implementation plan
        </a-tag>
        <a-tag slot="type" slot-scope="text" color="#3356b9" v-else-if="text=='date'">
            Timed execution
        </a-tag>
        <a-tag slot="type" slot-scope="text" color="#2d9bad" v-else-if="text=='interval'">
            Interval execution
        </a-tag>

        <a-tag slot="time" slot-scope="text,record" color="#348ceb">
            <a-tooltip placement="top" v-if="record.type=='cron'">
                <template slot="title">
                    <span v-if="String(record.start_date).trim()===''">Starting time: unregistered</span>
                    <span v-else>Starting time:{{record.start_date}}</span>

                    <span v-if="String(record.end_date).trim()===''">End time: No settings</span>
                    <span v-else>End Time:{{record.end_date}}</span>

                    <span>Random floating time:{{record.jitter}}</span>
                </template>
                {{ text }}
            </a-tooltip>
            <a-tooltip placement="top" v-if="record.type=='interval'">
                <template slot="title">
                    <span v-if="String(record.start_date).trim()===''">Starting time: unregistered</span>
                    <span v-else>Starting time:{{record.start_date}}</span>

                    <span v-if="String(record.end_date).trim()===''">End time: No settings</span>
                    <span v-else>End Time:{{record.end_date}}</span>

                    <span>Random floating time:{{record.jitter}}</span>
                </template>
                {{ text }}
                <span v-if="record.interval_type=='seconds'">Second</span>
                <span v-if="record.interval_type=='minutes'">Minute</span>
                <span v-if="record.interval_type=='hours'">Hour</span>
                <span v-if="record.interval_type=='days'">Day</span>
                <span v-if="record.interval_type=='weeks'">Week</span>
            </a-tooltip>
            <a-tooltip placement="top" v-if="record.type=='date'">
                <template slot="title">
                    <span>Execute at the specified time, only once execute</span>
                </template>
                {{ text }}
            </a-tooltip>
        </a-tag>

        <span slot="status" slot-scope="text,record">
            <span v-if="record.type=='cron'">
                <span v-if="String(record.end_date).trim()===''">
                    <a-tag v-if="text==0" color="#04b2ef">Stop...</a-tag>
                    <a-tag v-if="text==1" color="#469823">Execution...</a-tag>
                </span>
                <span v-else>
                    <span v-if="new Date(record.end_date).getTime()>=new Date().getTime()">
                        <a-tag v-if="text==0" color="#04b2ef">Stop...</a-tag>
                        <a-tag v-if="text==1" color="#469823">Execution...</a-tag>
                    </span>
                    <span v-else>
                        <a-tag color="#585858">over...</a-tag>
                    </span>
                </span>
            </span>
            <span v-if="record.type=='interval'">
                <span v-if="String(record.end_date).trim()===''">
                    <a-tag v-if="text==0" color="#04b2ef">Stopped</a-tag>
                    <a-tag v-if="text==1" color="#469823">Running ...</a-tag>
                </span>
                <span v-else>
                    <span v-if="new Date(record.end_date).getTime()>=new Date().getTime()">
                        <a-tag v-if="text==0" color="#04b2ef">Stopped</a-tag>
                        <a-tag v-if="text==1" color="#469823">Running ...</a-tag>
                    </span>
                    <span v-else>
                        <a-tag color="#585858">over...</a-tag>
                    </span>
                </span>
            </span>
            <span v-if="record.type=='date'">
                <span v-if="new Date(record.time).getTime()>=new Date().getTime()">
                    <a-tag v-if="text==0" color="#04b2ef">Stopped</a-tag>
                    <a-tag v-if="text==1" color="#469823">Running ...</a-tag>
                </span>
                <span v-else>
                    <a-tag color="#585858">over...</a-tag>
                </span>
            </span>
        </span>

        <div slot="create_time" slot-scope="text">
            {{Dayjs(text).format('YYYY-MM-DD HH:mm:ss')}}
        </div>

        <div slot="update_time" slot-scope="text">
            {{Dayjs(text).format('YYYY-MM-DD HH:mm:ss')}}
        </div>

        <div slot="action" slot-scope="text, record" >
            <span v-if="record.type=='date'">
                <span v-if="new Date(record.time).getTime()>=new Date().getTime()">
                    <a-tooltip placement="top" v-if="record.status==0">
                        <template slot="title">
                            <span>Start the execution script</span>
                        </template>
                        <a-button size="small" shape="circle" type="primary" style="background-color: #469824;border-color: #469824;" icon="play-circle" :loading="startPaueLoading" @click="onStartPause(record.timer_uuid,'start')"></a-button>
                    </a-tooltip>

                    <a-tooltip placement="top" v-if="record.status==1">
                        <template slot="title">
                            <span>Stop the script</span>
                        </template>
                        <a-button size="small" shape="circle" type="primary" icon="pause-circle" :loading="startPaueLoading" @click="onStartPause(record.timer_uuid,'pause')"></a-button>
                    </a-tooltip>
                </span>
                <span v-else>
                    <a-tooltip placement="top">
                        <template slot="title">
                            <span>It's over, it cannot be operated</span>
                        </template>
                        <a-button size="small" shape="circle" type="primary" style="background-color: #469824;border-color: #469824;" icon="play-circle" :loading="startPaueLoading" disabled></a-button>
                    </a-tooltip>
                </span>
            </span>
            <span v-else>
                <a-tooltip placement="top" v-if="record.status==0">
                    <template slot="title">
                        <span>Start the execution script</span>
                    </template>
                    <a-button size="small" shape="circle" type="primary" style="background-color: #469824;border-color: #469824;" icon="play-circle" :loading="startPaueLoading" @click="onStartPause(record.timer_uuid,'start')"></a-button>
                </a-tooltip>

                <a-tooltip placement="top" v-if="record.status==1">
                    <template slot="title">
                        <span>Stop the script</span>
                    </template>
                    <a-button size="small" shape="circle" type="primary" icon="pause-circle" :loading="startPaueLoading" @click="onStartPause(record.timer_uuid,'pause')"></a-button>
                </a-tooltip>
            </span>
        </div>
    </a-table>

</a-layout-content>
</template>

<script>
const columns = [{
        title: "Workflow",
        dataIndex: "name",
        key: "name",
        scopedSlots: {
            customRender: "name",
        },
        width: 260
    },
    {
        title: "Type",
        dataIndex: "type",
        key: "type",
        scopedSlots: {
            customRender: "type",
        },
        width: 100
    },
    {
        title: "Time interval",
        dataIndex: "time",
        key: "time",
        scopedSlots: {
            customRender: "time",
        }
    },
    {
        title: "Update time",
        key: "update_time",
        dataIndex: "update_time",
        scopedSlots: {
            customRender: "update_time",
        },
        width: 190
    },
    {
        title: "Creation time",
        key: "create_time",
        dataIndex: "create_time",
        scopedSlots: {
            customRender: "create_time",
        },
        width: 190
    },
    {
        title: "Status",
        key: "status",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status",
        },
        width: 100
    },
    {
        title: "Action",
        key: "action",
        scopedSlots: {
            customRender: "action",
        },
        width: 100
    },
];
export default {
    name: 'timerHome',
    data() {
        return {
            columns,
            loading: false,
            data: [],
            so_text: "",
            startPaueLoading: false,
            pagination: {
                total: 0,
                defaultPageSize: 10,
                showTotal: total => `common ${total} Article data`,
                showSizeChanger: true,
                pageSizeOptions: ['10', '15', '20', '50', '100'],
                onShowSizeChange: this.onPageShowSizeChange,
                onChange: this.onPageChange
            },
            curr_page: 1,
            curr_page_size: 10
        }
    },
    mounted() {
        this.onLoad();
    },
    methods: {
        onPageShowSizeChange(current, pageSize) {
            this.curr_page = current;
            this.curr_page_size = pageSize;
            this.onLoad(this.so_text, this.select_type, current, pageSize);
        },
        onPageChange(page, pageSize) {
            this.curr_page = page;
            this.curr_page_size = pageSize;
            this.onLoad(this.so_text, this.select_type, page, pageSize);
        },
        onLoad(keywords = "", page = 1, page_count = 10) {
            this.loading = true;
            this.$http
                .post("/api/v1/rest/get/timer/list", {
                    keywords: keywords,
                    page: page,
                    page_count: page_count
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.data = res.data.list;
                        this.pagination.total = res.data.total_count;
                        this.loading = false;
                    } else {
                        this.$message.error(res.msg);
                        this.loading = false;
                    }
                });
        },
        onStartPause(uuid, type) {
            this.startPaueLoading = true;

            this.$http
                .post("/api/v1/rest/post/timer/start_pause", {
                    uuid: uuid,
                    type: type
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.onLoad();
                        this.startPaueLoading = false;
                    } else {
                        this.$message.error(res.msg);
                        this.startPaueLoading = false;
                    }
                }).catch((err) => {
                    this.startPaueLoading = false;
                });
        },
        onTz(uuid) {
            this.$router.push({
                name: "WorkflowEdit",
                params: {
                    uuid: uuid
                }
            });
        },
        onSearch(value) {
            this.so_text = value;
            this.onLoad(this.so_text);
        },
    },
}
</script>

<style lang="less" scoped>
@import url("../../theme/style.less");

.pointer {
    cursor: pointer;
}

.center {
    text-align: center;
}

.bright {
    .header_div {
        width: 100%;
        background: #ffffff;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 8px;

        .align {
            vertical-align: middle;
        }

        .btn_add {
            float: right;
        }
    }

    .ant-table-wrapper {
        background: #fff;
        border-radius: 8px;
        padding: 5px 10px;

        /deep/ .ant-table-thead>tr>th {
            background: #fff;
        }
    }
}

.dark {
    .header_div {
        width: 100%;
        background: #202020;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 8px;

        .align {
            vertical-align: middle;
        }

        .btn_add {
            float: right;
        }
    }

    .ant-table-wrapper {
        background: #202020;
        border-radius: 8px;
        padding: 5px 10px;

        /deep/ .ant-table-thead>tr>th {
            background: #202020;
            border-bottom: 1px solid #303030;
        }
    }
}
</style>
